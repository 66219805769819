html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  font-size: 13px;
}

.webviewer {
  flex: 1;
  margin: 8px;
  -webkit-box-shadow: 1px 1px 10px #999;
          box-shadow: 1px 1px 10px #999;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container{
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
}

.fields {
  width: 250px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.field {
  display: block;
}
.menu {
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  padding: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 5px;
  border-radius: 5px;
}
.menuSmall {
  overflow: auto;
  padding: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 5px;
  border-radius: 5px;
}
.menuAuto {
  text-align: right;
  cursor: pointer;
  padding-bottom: 5px;
}
.menuButton {
  flex: 1;
  display: inline-block;
  height: 60;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 4px 8px;
  margin: 5px;
  text-align: center;
}

.inputText {
  flex: 1;
  display: block;
  height: 20px;
  border: 1px solid lightgrey;
  padding: 2px;
  margin: 5px;
  text-align: left;
  border-radius: 5px;
  font-size: 12px;
}
.inputTextLine {
  flex: 1;
  display: inline-block;
  height: 80;
  border: 1px solid lightgrey;
  padding: 5px;
  margin: 5px;
  text-align: left;
}

.webviewer {
  flex: 1;
  margin: 8px;
  -webkit-box-shadow: 1px 1px 10px #999;
          box-shadow: 1px 1px 10px #999;
}

.popup {
  position: 'absolute';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: lightgrey;
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.hide-in-mobile{
  display: block !important;
}

.addsign {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.addsignButton{ 
  align-self: stretch;
  flex: 1;
  height: 40px;
  overflow: auto;
  padding: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
}
.validSignButton {
  overflow: auto;
  padding: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 5px;
  border-radius: 5px;
  font-size: 14px;

}
button{
  background-color: #FFFFFF;
  border-width: 0px;
  cursor: pointer;
}
button:focus {outline:0;}
